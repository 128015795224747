<style lang="scss">

  .units {
    display: flex;
    align-items: center;
    flex-direction: column;

    .container {
      .unit-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        top: 200px;
        margin-bottom: 100px;

        @include tablet {
          top: 70px;
          position: relative;
        }
      }
    }
  }

</style>

<template lang="pug">
.units
  .container
    Presentation
    .unit-card
      template(v-for="(unit, index) in units" )
        Card(
          :unit="unit",
          :key="index"
        )

</template>

<script>

import Card from './components/card/index.vue';
import Presentation from './components/presentation.vue';

export default {
  name: 'Units',

  components: {
    Card,
    Presentation
  },

  data() {
    return {
      units: []
    };
  },

  methods: {
    getUnits() {
      this.$api.get('/unidades')
        .then((response) => {
          this.units = response.data;
          this.units.sort((a, b) => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          });
        });
    }
  },

  mounted() {
    this.getUnits();
  },

  computed: {
    backgroundImage() {
      return {
        // backgroundImage: `url(${this.unit.background[0].url})`
      };
    }
  }

};

</script>
