<style lang="scss">

.presentation-units {
  width: 100%;
  height: auto;

  @include tablet {
    // height: 280px !important;
    padding: 0 60px;
  }

  .presentation__content {
    text-align: left;
    position: relative;
    top: 150px;
    z-index: 100 !important;

    @include tablet {
      top: 70px;
    }

    &-title {
      font-size: 4.5rem;
      line-height: 82px;
      font-weight: 500;
      margin-top: 30px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: $title-font;

      @include tablet {
        font-size: 50px;
        line-height: 60px;
      }

      span {
        display: block;
        font-weight: bold;
      }
    }
  }

  &__img-builds {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: down-up 1s ease-out;
  }

  @keyframes down-up {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes slide-in {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @keyframes shows-up {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}

</style>

<template lang="pug">
  .presentation-units
    //.presentation__img(:style="getBackground")
    .presentation__content
      Title Explore nossa história
      //span.presentation__content-subtitle Explore nossa história
      h1.presentation__content-title Unidades
      //p.presentation__content-roll Role a página

    //- img.presentation__img-builds(src="@/assets/img/home/predios.webp")

</template>

<script>

export default {
  name: 'Presentation',

  methods: {
    getBackground(index) {
      return {
        backgroundImage: `linear-gradient(180deg, transparent, black), url(${this.slides[index].image}) `
      };
    }
  }

};

</script>
