<style lang="scss">

.card {
  max-width: 400px;
  width: 400px;
  height: 350px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 0 10px 10px 0;
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    width: 400px;
    max-width: 400px;
    margin: 0 0 30px 0;
  }

  .logo {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin: 10px 12px 0 0;
  }

  .action {
    width: 100%;
    height: 150px;
    background: rgba(0, 0, 0, 0.7);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    position: relative;
    animation: slide-up-fade-in ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:forwards;

    .title {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: .3rem;
      text-transform: uppercase;
      color: #FBD784;
      margin-bottom: 5px;
    }

    .service-type {
      width: 100%;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include tablet {
        white-space: normal;
      }
    }

    .btn {
      border: none;
      font-size: 17px;
      font-weight: 700;
      margin-top: 20px;
      padding: 10px 20px;
      border-radius: 8px;
      text-decoration: none;
      text-transform: uppercase;
      color: $text-color-secondary;
      background-color: $color-secondary-dark;
    }
  }

  @keyframes slide-up-fade-in{
    0% {
      opacity:0;
      transform:  translate(0px,0px)  ;
    }
    100% {
      opacity:1;
      transform:  translate(0px,0px)  ;
    }
  }
}

</style>

<template lang="pug">

  .card(
    :style="{ backgroundImage: `url(${backgroundImage})` }",
    @mouseover="active = true" @mouseleave="active = false"
  )
    .logo
      img(:src="logo" width="150")

    .action(v-show="active")
      .title
        span {{ unit.nome }}

      .service-type(v-tooltip.bottom-center="tooltip")
        span {{ unit.tipo_de_servicos }}

      router-link.btn(:to="{ name: 'Unit', params: { id: unit.id } }") Acessar

</template>

<script>
export default {
  name: 'UnitCard',

  props: {
    unit: { type: Object, default: () => {} }
  },

  data() {
    return {
      active: false
    };
  },

  computed: {
    backgroundImage() {
      return `${process.env.VUE_APP_API}${this.unit.background[0].url}`;
    },

    logo() {
      return `${process.env.VUE_APP_API}${this.unit.logo[0].url}`;
    },

    tooltip() {
      return {
        content: this.unit.tipo_de_servicos
      };
    }
  }
};
</script>
